import React from "react";
import { Link } from "gatsby";

import { LayoutBase as Layout } from "layouts";
import { SEO } from "components";

import "styles/page-404.scss";

const NotFoundPage = ({ location, pageContext }) => (
  <Layout layoutProps={{ location, footerData: pageContext.footerData, pageNavData: pageContext.pageNavData }}>
    <SEO>
      <title>Page Not Found</title>
      <meta name="description" content="Page Not Found" />
    </SEO>
    <div className="page-content-wrapper">
      <div className="page-section m-pnav">
        <article className="text-block-404">
          <h1 className="h1">
            The page that you are looking for is <span>not found</span>.
          </h1>
          <Link to="/">Back to home page</Link>
        </article>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
